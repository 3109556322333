import { useDispatch } from "react-redux" ; 
import { ChildrenSignUpProps, initialForm } from "../ForgotPwd" ; 
import { useEffect, useState } from "react"
import { addRequestStatus } from "../../../store/global/actions.global";
import MainButton from "../../../tools/components/general/MainButton";




export default function Code(props:ChildrenSignUpProps) {
    const dispatch = useDispatch()
    //VERIFICATION CODE (STATE AND VARIABLES)
    const [sendCode, setSendCode] = useState(60)


    //BOTH (STATE AND VARIABLES)
    //const recaptchaRef = useRef(null)
    //const [attempt, setAttempt] = useState(0)
    //const [recaptcha, setRecaptcha] = useState("")

    const handleReSend = () => {
        setSendCode(60)
        props.setForm(state => {return{...state, code : ""}})
        props.handleSubmit()
    }

    const handleSubmit = () => {
        props.setManager(state => {return{...state, initialization : "pending"}})

        props.server.post('checking-code', {token : props.form.token, code : props.form.code})
        .then(res => {
            props.setManager(state => {return{...state, mode : "pwd"}})
            props.setForm(state => {return{...state, token : res.data.token}})
            //dispatch(manage_loader(false))
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            //dispatch(manage_loader(false))
            console.log(err)
            props.setManager(state => {return{...state, initialization : "checking-code"}})
            props.setForm(state => {return{...state, code : ""}})
            dispatch(addRequestStatus(err.response.data))
        })
    }

    //VERIFICATION CODES (FUNCTIONS)
    useEffect(() => {
        const timer = setInterval(() => {
            if(sendCode > 0) {
                setSendCode((prevSeconds) => prevSeconds - 1);
            }
          }, 1000);
      
          return () => clearInterval(timer);
    }, [sendCode])


    return (
        <div className='forgot-pwd--code'>
            <h1>Code de vérification</h1>
            <p>
                <span>Un code de vérification vient de vous être envoyé sur l'adresse mail suivante : {props.form.mail}.</span> 
                <span>Veuillez entrer le code que vous avez reçu afin de réinitialiser votre mot de passe. </span>
            </p>
            <input 
                type = "text"
                name = "code"
                placeholder='XXXXXX'
                value = {props.form.code}
                onChange = {(e) => props.setForm(state => {return{...state, code : e.target.value.replace(/\D/g, "")}})}
            />
            <MainButton handleClick = {handleSubmit}>Valider</MainButton>
            <div
                className={`resend ${sendCode === 0 ? "resend__active" : ""}`}
                onClick = {() => sendCode === 0 ? handleReSend() : null}
            >      
                {sendCode !== 0 ? `Renvoyer le code (${sendCode})` : "Vous n'avez pas reçu le code ? Cliquez-ici." }
            </div>

        </div>
    )
}