//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

//const hostname = 'http://localhost:4000'
const hostname = 'https://pulsify.tropicode.re'

// * SOCKET CONFIG ///////////``
export const socket_server = io(hostname, { transports : ['polling']});

// * NODEJS SERVER ///////////
//export const server_host = (route:string="") => { return `https://mynyomi.fr/auth/${route}`}
export const server_host = (route:string="") => { return `${hostname}/auth/${route}`}


// * REDIRECT URL ///////////
export const redirect_url_admin = `https://admin.mynyomi.fr/`
export const redirect_url_app = `https://app.mynyomi.fr/`