import {ReactComponent as Logo} from '../assets/svg/logo.svg'
import logo_min from '../assets/images/logo_minified.png'
import {ReactComponent as Boxes} from '../assets/svg/icons/boxes-stacked-duotone.svg' ; 
import {ReactComponent as Containers} from '../assets/svg/icons/container-storage-duotone.svg' ;
import {ReactComponent as UserShield} from '../assets/svg/icons/user-shield-duotone.svg' ;
import React from 'react';

// * COMPAGNY
export const compagny = {
    logo : <Logo />, 
    logo_min : logo_min
}

// * ICONS
export const icons_config = {
    mode : "duotone", 
    color : "#306F1D" 
}

// * MODULES

interface mod_links_interface {
    id : string, 
    name : string, 
    svg : JSX.Element, 
    //subdomain : string, 
    text: string
}

const boxesElement: JSX.Element = React.createElement(Boxes);
const containersElement: JSX.Element = React.createElement(Containers);
const usershieldElement: JSX.Element = React.createElement(UserShield);

export const modules_links:mod_links_interface[] = [
    {
        id : "MOD-LINKS-0",  
        name : "admin", 
        text : "Administration",
        svg : usershieldElement,
    }, 
    {
        id : "MOD-LINKS-1",  
        name : "user", 
        text : "Utilisateur",
        svg :  boxesElement,
    }, 
    {
        id : "MOD-LINKS-02", 
        name : "containers", 
        text : "Conteneurs",
        svg :  containersElement,
    }, 
]