import React, {useEffect, useRef, useState} from 'react' ; 
import {motion} from 'framer-motion';
import ReCAPTCHA from 'react-google-recaptcha';
import { ServerProps, redirect_url_admin, redirect_url_app } from "../../config/server.config";
import ContainerLoad from '../../tools/components/general/ContainerLoad';
import {ReactComponent as Logo} from '../../assets/svg/logo2.svg'
import {ReactComponent as OnTheWayRafiki} from '../../assets/svg/illustrations/on-the-way-rafiki.svg'
import { useDispatch } from 'react-redux';
import { addRequestStatus } from '../../store/global/actions.global';
import { set_authentification } from '../../store/account/actions.account';
import MainButton from '../../tools/components/general/MainButton';
import { Link } from 'react-router-dom';
import ChooseModules from './layouts/ChooseModules';

type SignInManager = {
    mode : "form" | "load" | "modules" | "redirect" 
}

type SignInForm = {
    mail : string,
    pwd : string,
    recaptcha : string | null
}

export default function SignIn(props:ServerProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const emailRef = useRef<HTMLInputElement>(null);
    const pwdRef = useRef<HTMLInputElement>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);    

    // * STATES
    const [manager, setManager] = useState<SignInManager>({mode :  "form"})
    const [form, setForm] = useState<SignInForm>({mail : "", pwd : "", recaptcha : null})

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => { setForm(state => { return { ...state, [e.target.name]: e.target.value }; }) };

    // * FUNCTIONS
    //Submit login
    const handleSubmit = () => {
        // # IF # 3 attempts : Need to complete a recaptcha
        
        if (parseInt(localStorage.getItem('attempt') || '') === 3 && form.recaptcha === null) {
            let response = {
                status : false, 
                message : "Veuillez compléter le ReCaptcha."
            }
            return dispatch(addRequestStatus(response))
        }

        // # IF # Mail pattern verification : Raise an error if not
        if(!form.mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)) {
            let response = {
                status : false, 
                message : "L'adresse mail est invalide."
            }
            return dispatch(addRequestStatus(response))
        }

        // # IF # Password length verification
        if(form.pwd.length < 5 ) {
            let response = {
                status : false, 
                message : "Le mot de passe est invalide"
            }
            return dispatch(addRequestStatus(response))
        }

        setManager(state => {return{...state, mode : "load"}})

        return props.server.post('sign-in', form)
        .then(res => {
            localStorage.clear()
            dispatch(set_authentification({infos : res.data.data, status : true, token : res.data.data.token_session}))
            console.log(res.data)
            if(res.data.data.modules.length === 1) {
                setManager(state => {return{...state, mode : "redirect"}})
                
                setTimeout(() => {
                    window.location.href = `${res.data.data.modules[0].subdomain}?token=${res.data.data.modules[0].token}`;
                }, 5000); // Délai de 5 secondes

            } else {
                setManager(state => {return{...state, mode : "modules"}})
            }


            /* 
            setTimeout(() => {
                if(res.data.data.redirection_type === "admin") {
                    //window.location.href = `${redirect_url_admin}?token=${res.data.data.token_session}`; 
                } else {
                    //window.location.href = `${redirect_url_app}?token=${res.data.data.token_session}`; 
                }

            }, 5000); // Délai de 5 secondes
            */

            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            if(!localStorage.getItem('attempt')) {
                localStorage.setItem('attempt', "1")
            } else {
                const attempt = parseInt(localStorage.getItem('attempt') || '')
                if(isNaN(attempt) || attempt > 3 || attempt < 1) {
                    localStorage.setItem('attempt', "3")
                } else if (attempt < 3 && attempt > 0) {
                    localStorage.setItem('attempt', `${attempt + 1}`)
                } else {
                    //REVOIR LE RESET 
                    recaptchaRef.current?.reset();
                }

            }
            
            setManager(state => {return{...state, mode : "form"}})
            dispatch(addRequestStatus(err.code !== "ERR_NETWORK" ? err.response.data : {status : false, message : "Erreur réseau, veuillez vérifier votre connexion internet ou le cas échéant, contacter l'administrateur du site."}))
        })

    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter") {
            handleSubmit()
        }
    }

    // * USE EFFECTS
    useEffect(() => {
        if (emailRef.current && !emailRef.current.value) {
          emailRef.current.focus();
        } else if (pwdRef.current) {
          pwdRef.current.focus();
        }
    }, []);


    return (
        
        <div className = "sign-in">
        {/* FORM */}
        <motion.div 
            exit = {{y : 50, opacity : 0}}
            animate = {{y : 0, opacity : 1, transition : {delay : 0.3, type : "linear"}}}
            initial = {{y : 50, opacity : 0}}
            className={`form ${manager.mode === "redirect" ? "form--redirect" : ""}`}
        >
            <ContainerLoad load = {manager.mode === "load"}>
                {
                    manager.mode === "redirect" ?
                        <React.Fragment>
                            <div className='logo'><Logo /></div>
                            <div className='content'>
                                <div className='redirect'>
                                    <OnTheWayRafiki />
                                    <p>Redirection en cours...</p>
                                </div>
                            </div>
                        </React.Fragment>
                    : 

                        manager.mode === "modules" ? 
                            <ChooseModules />
                        : 
                            <React.Fragment>
                                <div className='logo'><Logo /></div>
                                <div className='content'>
                                    <div>
                                        <label htmlFor = "mail">Identifiant</label>
                                        <input
                                            type = "email"
                                            ref = {emailRef}
                                            id = "mail"
                                            name = "mail"
                                            value = {form.mail}
                                            onKeyDown={handleKeyDown}
                                            placeholder = "xxxxx@example.com"
                                            onChange = {handleChangeInput}
                                        />
                                    </div>
                                <div>
                                    <label htmlFor = "pwd">Mot de passe</label>
                                    <input
                                        type = "password"
                                        id = "password"
                                        name = "pwd"
                                        ref = {pwdRef}
                                        placeholder = "xxxxxxx"
                                        onKeyDown={handleKeyDown}
                                        value = {form.pwd}
                                        onChange = {handleChangeInput}
                                    />
                                </div>
                                {
                                    localStorage.getItem('attempt') === "3" ? 
                                        <ReCAPTCHA
                                            sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                                            ref = {recaptchaRef}
                                            id = {'Recaptcha'}
                                            
                                            onChange={(value) => setForm(state => {return{...state, recaptcha : value}})}
                                        />
                                    : 
                                        null
                                }
                                <MainButton
                                    handleClick = {handleSubmit}
                                >
                                    Valider
                                </MainButton>
                                <Link
                                    to = "/forgot-pwd"
                                    className='button-forgot-pwd'
                                >
                                    Mot de passe oublié ? Cliquez ici
                                </Link>
                                </div>
                            </React.Fragment>
                }
            </ContainerLoad>
        </motion.div>

        {/* ILLUSTRATION */}
        <motion.div 
            className='illustration'
            exit = {{scale : 0}}
            animate = {{scale : 1, transition : {delay : 0.2, type : "linear"}}}
            initial = {{scale : 0}}
        >

        </motion.div>
        </div>
    )
}