import {motion} from "framer-motion";

interface InstallAppProps {
    handleInstall: () => void;
}

export default function InstallApp(props: InstallAppProps) {
    return (
        <motion.div 
            exit = {{y : 100, opacity : 0}}
            animate = {{y : 0, opacity : 1, transition : {delay : 1, type : "linear"}}}
            initial = {{y : 100, opacity : 0}}
            className="service-worker--install-app"
        >
            <p>
                <span>C'est l'heure de prendre le contrôle de votre journée avec Pulsify.</span>
                <span> Téléchargez l'application dès maintenant et découvrez une gestion du temps simplifiée et efficace.</span>
            </p>
            <button className="install-app__button" onClick={props.handleInstall}>
                Pulsify Now
            </button>
        </motion.div>
    );
};