import React, { useEffect } from 'react';
import './styles/style.css';
import './styles/normalize.css';
import Auth from './auth/Auth';
import BasicTools from './tools/components/basictools/BasicTools';
import { useSelector } from 'react-redux';
import { account_user } from './store/account/selector.account';
import InstallApp from './serviceWorker/InstallApp';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>;
  prompt(): Promise<void>;
}

interface ServiceWorkerProps {
  isAppInstalled : boolean | null;
  installPromptEvent : BeforeInstallPromptEvent | null;
}

function App() {
  const [serviceWorker, setServiceWorker] = React.useState<ServiceWorkerProps>({
    isAppInstalled : null, 
    installPromptEvent : null
  });
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;


  //CHECK IF THE INSTALLATION IS POSSIBLE
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Empêcher le mini-infobar d'apparaître sur mobile
      e.preventDefault();

      // Sauvegarder l'événement pour pouvoir le déclencher plus tard
      // Mettre à jour l'état pour indiquer que l'installation est possible
      setServiceWorker((state:any) => {
        return {
          ...state,
          installPromptEvent : e, 
          isAppInstalled : false
        }
      })
    });

    window.addEventListener('appinstalled', () => {
      // L'utilisateur a installé l'application, mettre à jour l'état
      setServiceWorker((state:any) => {
        return {
          ...state,
          isAppInstalled : true
        }
      })
    });

    // Vérifier si l'application est déjà lancée en mode app
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setServiceWorker((state:any) => {
        return {
          ...state,
          isAppInstalled : true
        }
      })
    }
  }, []);

  const handleInstallClick = () => {
    // Montrer le prompt d'installation
    const installEvent = serviceWorker.installPromptEvent;
    if (installEvent) {
      installEvent.prompt();
  
      // userChoice est la promesse sur laquelle vous voulez utiliser then
      installEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Utilisateur a accepté l\'installation');
        } else {
          console.log('Utilisateur a refusé l\'installation');
        }
        setServiceWorker((prevState) => ({
          ...prevState,
          installPromptEvent: null,
        }));
      });
    }
  };
  


  return (
    <React.Fragment>
      {
        (serviceWorker.isAppInstalled === false && serviceWorker.installPromptEvent !== null) ? (
          <InstallApp handleInstall={handleInstallClick}/>
        ) : null
      }
      <BasicTools />
      <Auth />
    </React.Fragment>
  );
}

export default App;
