import { useSelector } from "react-redux"
import {motion} from 'framer-motion'
import { account_user } from "../../../store/account/selector.account"
import { modules_links } from "../../../config/context.config"
import {ReactComponent as OpenInTab} from '../../../assets/svg/icons/square-arrow-up-right-duotone.svg'



export interface ChooseModulesProps {
    
}

export default function ChooseModules(props:ChooseModulesProps) {
    const user_account = useSelector(account_user)
    const modules = user_account.infos.modules.map((mod:any) => {
        const context_mod = modules_links.find(mod_link => mod_link.name === mod.name)

        return {
            ...context_mod, 
            ...mod
        }
    })

    const handleClick = (mod:any) => {
        window.location.href = `${mod.subdomain}?token=${mod.token}`; 
    }


    return (
        <div className = "choose-modules">
            <h1>Veuillez choisir votre module</h1>
            <div>
            {
                modules.map((mod:any) => (
                    <motion.div
                        whileHover={{scale : 1.02}}
                        whileTap = {{scale : 0.98}}
                        onClick = {() => handleClick(mod)}
                        key = {mod.id}
                    >
                        {mod.svg}
                        <p>
                            <span>{mod.text}</span>
                            <span>{mod.subdomain}</span>
                        </p>
                        <div className = "open-in-tab"><OpenInTab /></div>
                    </motion.div>
                ))
            }
            </div>
        </div>
    )
}